import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import FlexContainer2 from "../components/common/FlexContainer2";
import more from "../assets/click on Intelligence platform/find formula for success.jpg";
import CustomCarousel from "../components/common/Slider";
import Tabs from "../components/IntellegencePlatform/Tabs";
import Spacer from "../components/common/Spacer";
import cover from "../assets/click on Intelligence platform/click on sports.jpg";
import MobileCover from "../assets/click on Intelligence platform/mobile-sports.jpg";
import VideoSlider from "../components/common/VideoSlider";
import ContentBanner from "../components/sports/ContentBanner";
import Categories from "../components/sports/Categories";
import LineHeading from "../components/sports/LineHeading";
import Comment from "../components/sports/Comment";
import startup from "../assets/click on Intelligence platform/google.jpg";
import google2 from "../assets/click on Intelligence platform/google2.jpg";
import vision from "../assets/click on Intelligence platform/computer-vision.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


const Sports = () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  const navigate=useNavigate()
  const data = [
    {
      category: "Performance Medicine",
      // title: "PLAY SMARTER",
      link:"/intell/performance/med",
      summary:
        "Avoid being vulnerable to outdated EMR systems. Manage risk and compliance while protecting data to enable reliable, consistent decision-making.",
      features: [
        {
          title: "Medical History",
          details:
            "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
        },
        {
          title: "Wellness & RPE Collection",
          details:
            "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
        },
        {
          title: "Injury Lifecycle Management",
          details:
            "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
        },
      ],
    },
    {
      category: "Performance Optimization",
      // title: "OPTIMIZE YOUR HUMAN ASSETS",
      link:"/intell/performance/opt",

      summary:
        "Integrate data from the game, gym, physical testing, and other sources with medical information to get a comprehensive picture of each player's condition, progress, and objectives.",
      features: [
        {
          title: "Metric Management & Analysis",
          details:
            "Consolidate all of your training, fitness, testing, and other data, and set up how you generate, see, and control related measures.",
        },
        {
          title: "Player Profiling & Benchmarking",
          details:
            "Create a comprehensive image of every player using any performance-related metric. Evaluate their current situation, past experiences, and relative merits.",
        },
        {
          title: "Session Planning",
          details:
            "Create and monitor thorough training schedules that are in line with the fundamentals of each organization's particular style of play.",
        },
      ],
    },
    {
      category: "Coaching & Development",
      // title: "DATA THAT DRIVES RESULTS",
      link:"/intell/performance/c&d",

      summary:
        "By monitoring each athlete's growth, performance, and involvement, you can equip your coaches with the resources they need to organize their talent pipeline and promote player and team success.",
      features: [
        {
          title: "Individual Development & Planning",
          details:
            "Set individual goals and create action plans across all elements of play and physical/mental development.",
        },
        {
          title: "Growth & Maturation",
          details:
            "Collect growth and maturation data for each athlete  (including academy), tracking factors around puberty to support better decision-making.",
        },
        {
          title: "Participation Management",
          details:
            "Understand the impact of injuries on availability to inform how activities should be limited, modified or not performed due to injury",
        },
      ],
    },
  ];
  const data2 = [
    {
      category: "League Operations",
      link:"",

      // title: "PLAY SMARTER",
      summary:
        "Simplify league-wide procedures and monitor team, player, and staff activity from daily tasks to season-long broad-based planning.",
      features: [
        {
          title: "Player Registration",
          details:
            "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
        },
        {
          title: "Staff & Official Registration",
          details:
            "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
        },
        {
          title: "Fixture Management",
          details:
            "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
        },
      ],
    },
    {
      category: "Custom Analytics",
      // title: "OPTIMIZE YOUR HUMAN ASSETS",
      link:"",

      summary:
        "Integrate data from the game, gym, physical testing, and other sources with medical information to get a comprehensive picture of each player's condition, progress, and objectives.",
      features: [
        {
          title: "Data Health",
          details:
            "To achieve the intended results, evaluate the data strategy and process, pinpoint areas for improvement, and put systems in place.",
        },
        {
          title: "Injury Review",
          details:
            "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        },
        {
          title: "Success Landscape",
          details:
            "Learn about the tactics used by successful teams and the adjustments you can make to succeed based on data.",
        },
      ],
    },
  ];

  const content = {
    head1:
      "Integrate your people, data, and processes with the IP: Intelligence platform.",
    head2: "PERFORMANCE DATA",
    head3: "SYSTEMIZE YOUR TALENT DEVELOPMENT PIPELINE",
    head4: "STREMLINE AND CENTRALIZE OPERATIONS & LOGISTICS",
  };
  const MainCoverData = {
    title1: "MORE THAN A SPORTS EMR, BEYOND ATHLETE MONITORING SOFTWARE",
    title2: "SPORTS ",
    title3: "",
    summary:
      "Regardless of the field, pitch, or court you play on, you may succeed by planning, preparing, and performing. All of your athletes' human performance can be unlocked, and your team and league can achieve important performance measurements and results.",
    cover: isMobile?MobileCover:cover,
  };
  const videos = [
    {
      img:startup,
      video:
        "https://x.com/i/status/1816851062147703188",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India,@iitmadras has its sights set on #Olympics 2036. At #IITMSportsTechConclave, the institute said it will incubate 300 sports tech #startups to enable Indian athletes close the gap with global frontrunners.@akhil_edit reports",
      link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
      
    },
    {
      img:google2,
      video:
        "https://videos.pexels.com/video-files/6338366/6338366-uhd_2560_1440_25fps.mp4",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      link:"https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette"

    },
    {
      img:vision,
      video:
        "https://videos.pexels.com/video-files/5972853/5972853-uhd_2560_1440_30fps.mp4",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

    },
  ];
  const tabData=[
    {
        tabName:"PM",
        pages:[
            {
                page1:"",
                data:[
                    {
                        title:"Medical History",
                        summary:"Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location."

                    },
                    {
                        title:"Wellness & RPE Collection",
                        summary:"Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.."

                    },
                    {
                        title:"Injury Lifecycle Management",
                        summary:"Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation."

                    },
                    

                ]
            },
            // {
            //   page2:"",
            //   data:[
            //     {
            //       title:"Session Planning & Analysis",
            //       summary:"Develop and disseminate training programs that are in line with performance and development goals; monitor and evaluate the quality of sessions, player engagement, and evaluations."

            //   },
             
            //   {
            //       title:"Game Planning & Analysis",
            //       summary:"Create and distribute game rosters; monitor and evaluate team configurations, player performance, minutes, and disciplinary actions; and track and analyze game outcomes."

            //   },
            //   {
            //       title:"Communication & Player Engagement",
            //       summary:"Use centralized calendar management and real-time messaging to communicate with players, coaches, and staff."

            //   },

            //   ]
            // }
        ]
        

    },
    {
        tabName:"PO",
        pages:[
            {
                page1:"",
                data:[
                  {
                    title: "Metric Management & Analysis",
                    summary:
                      "Consolidate all of your training, fitness, testing, and other data, and set up how you generate, see, and control related measures.",
                  },
                  {
                    title: "Player Profiling & Benchmarking",
                    summary:
                      "Create a comprehensive image of every player using any performance-related metric. Evaluate their current situation, past experiences, and relative merits.",
                  },
                  {
                    title: "Session Planning",
                    summary:
                      "Create and monitor thorough training schedules that are in line with the fundamentals of each organization's particular style of play.",
                  },
                ]
            }
        ]
        

    },
    {
      tabName:"C & D",
      pages:[
          {
              page1:"",
              data:[
                {
                  title: "Individual Development & Planning",
                  summary:
                    "Set individual goals and create action plans across all elements of play and physical/mental development.",
                },
                {
                  title: "Growth & Maturation",
                  summary:
                    "Collect growth and maturation data for each athlete  (including academy), tracking factors around puberty to support better decision-making.",
                },
                {
                  title: "Participation Management",
                  summary:
                    "Understand the impact of injuries on availability to inform how activities should be limited, modified or not performed due to injury",
                },
              ]
          }
      ]
      

  },
    
]
const tabData2=[
  {
      tabName:"League Operations",
      summary:
        "Simplify league-wide procedures and monitor team, player, and staff activity from daily tasks to season-long broad-based planning.",
      pages:[
          {
              page1:"",
              data:[
                {
                  title: "Player Registration",
                  summary:
                    "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
                },
                {
                  title: "Staff & Official Registration",
                  summary:
                    "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
                },
                {
                  title: "Fixture Management",
                  summary:
                    "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
                },
              ]
          },
      ]
      

  },
  {
      tabName:"Custom Analytics",
      summary:
      "Integrate data from the game, gym, physical testing, and other sources with medical information to get a comprehensive picture of each player's condition, progress, and objectives.",
      pages:[
          {
              page1:"",
              data:[
                {
                  title: "Data Health",
                  summary:
                    "To achieve the intended results, evaluate the data strategy and process, pinpoint areas for improvement, and put systems in place.",
                },
                {
                  title: "Injury Review",
                  summary:
                    "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
                },
                {
                  title: "Success Landscape",
                  summary:
                    "Learn about the tactics used by successful teams and the adjustments you can make to succeed based on data.",
                },
              ]
          }
      ]
      

  },
  
]
  return (
    <div
      style={{ gap: isMobile?"1rem":"4rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData} />
      <ContentBanner data={content} />
      <Spacer height={"3rem"} />

      <div data-aos="fade-right"className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SOLUTION CAPABILITIES
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      {
        isMobile?<Tabs isSports={true} tabData={tabData}/>:<Categories category={data} />
      }
      
      <Spacer height={"5rem"} />
      
      {
        isMobile?<Tabs isSports={true} tabData={tabData2}/>:<Categories isTwo={true} category={data2} />
      }
      <Spacer height={"5rem"} />
      
      <LineHeading/>
      <Comment comment={"The way clubs and the Premier League can use data to enhance the development of elite players and individual growth across all club academies has advanced significantly with this cooperation."}/>
      <Spacer height={"2rem"} />

      <div data-aos="fade-right" className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SEEN & HEARD
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <VideoSlider slides={videos} />
      {/* <Spacer height={"1rem"} /> */}
      <div className={`${styles?.["flex-column"]} ${styles?.["w-100"]}`}>
        <h1 onClick={()=>navigate("/contact")} className={`${styles?.["orange-background"]} ${styles?.["pointer"]}`}>Contact Us</h1>
      </div>
      <Spacer height={"1rem"} />

    </div>
  );
};
export default Sports;

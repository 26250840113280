import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/home/MainCover";
import LineHeading from "../components/home/lineHeading";
import football from "../assets/football.png";
import basketball from "../assets/basketball.png";
import FlexContainer from "../components/common/FlexContainer";
import BoldHRWhite from "../components/common/BoldHRWhite";
import dataviz from "../assets/Home Page/data viz.jpg";
import Spacer from "../components/common/Spacer";
import PathPerformance from "../components/home/PathPerformance";
import ground1 from "../assets/Home Page/ground1.jpg";
import ground2 from "../assets/Home Page/ground2.jpg";
import config from "../assets/Home Page/configurable.jpg";

import Categories from "../components/home/Categories";
import CoverContent from "../components/home/CoverContent";
import SecuritySection from "../components/home/SecuritySection";
import WorkWithSection from "../components/home/WorkWithSection";
import Media from "../components/home/Media";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Home = () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  const navigate = useNavigate();
  AOS.init({
    duration: 3000,
  });
  const WhatsDo = [
    {
      title: "WE USE...",
      img: basketball,
      summary:
        "Data as a foundation for developing unique strategies that redefine and assist leagues, governing bodies, clubs, and teams in achieving the highest possible level of human performance.",
    },
    {
      title: "WE LEVERAGE...",
      img: football,
      summary:
        "Our in-house technology, subject-matter knowledge, and your data to achieve the best possible performance results at the top levels of defense and sports.",
    },
  ];
  return (
    <div className={`${styles?.["flex-column-str"]}`}>
      <div>
        <MainCover />
      </div>

      <LineHeading />
      {WhatsDo?.map((data, i) => (
        <FlexContainer isLeft={i % 2 != 0} data={data} />
      ))}
      <BoldHRWhite />
      {
        isMobile?<div
        data-aos="fade-up"
        className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
        style={{ gap: "2rem",flexWrap:"wrap" }}
      ><div className={`${styles?.["w-100"]} `}>
          <img
            //   height=
            className={`${styles?.["news"]} ${styles?.["w-100"]}`}
            src={dataviz}
            alt=""
          />
        </div>
        <div
          style={{ lineHeight: "39px", textAlign: "justify" }}
          className={` ${styles?.["w-100"]}  ${styles?.["value-cover"]} `}
        >
          <div
            className={` ${styles?.["head-lg"]}  ${styles?.["left"]} ${styles?.["head-xlg"]}  `}
          >
            Leaders in Data Visualization
          </div>
          <Spacer height="2rem" />
          <div className={`${styles['head-xsm']}`} style={{ lineHeight: "22px", textAlign: "justify" }}>
            With a user experience that is unmatched in our business, the ARMS
            system is revolutionizing the analysis and visualization of athlete
            data for over 250,000 athletes across more than 40 different sports
            worldwide. To provide you the whole picture, we are the first to
            completely centralize testing, performance, and health data into a
            single system. Your data will be given new life by our graphic
            dashboards.
          </div>
          <Spacer height={"2rem"} />
          <div
            className={`   ${styles?.["white-back-button"]} `}
            onClick={() => navigate("contact")}
          >
            Schedule a Demo
          </div>
        </div>

        
      </div>:<div
        data-aos="fade-up"
        className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
        style={{ gap: "2rem" }}
      >
        <div
          style={{ lineHeight: "39px", textAlign: "justify" }}
          className={` ${styles?.["w-70"]}  ${styles?.["value-cover"]} `}
        >
          <div
            className={` ${styles?.["head-lg"]}  ${styles?.["left"]} ${styles?.["head-xlg"]}  `}
          >
            Leaders in Data Visualization
          </div>
          <Spacer height="2rem" />
          <div style={{ lineHeight: "32px", textAlign: "justify" }}>
            With a user experience that is unmatched in our business, the ARMS
            system is revolutionizing the analysis and visualization of athlete
            data for over 250,000 athletes across more than 40 different sports
            worldwide. To provide you the whole picture, we are the first to
            completely centralize testing, performance, and health data into a
            single system. Your data will be given new life by our graphic
            dashboards.
          </div>
          <Spacer height={"2rem"} />
          <div
            className={`   ${styles?.["white-back-button"]} `}
            onClick={() => navigate("contact")}
          >
            Schedule a Demo
          </div>
        </div>

        <div className={`${styles?.["w-35"]} `}>
          <img
            //   height=
            className={`${styles?.["news"]} ${styles?.["w-100"]}`}
            src={dataviz}
            alt=""
          />
        </div>
      </div>
      }
      
      <BoldHRWhite />
      <div data-aos="fade-up">
        <PathPerformance />
      </div>
      <div data-aos="fade-up">
        <img className={`${styles?.["w-100"]}`} src={ground1} alt="" />
      </div>
      <div data-aos="fade-up">
        <Categories />
      </div>
      <div data-aos="fade-up">
        <CoverContent
          img={ground2}
          text={"MORE THAN AN EMR"}
          text2={"BEYOND AN AMS"}
        />
      </div>

      <div
        data-aos="fade-up"
        className={`${styles?.["m-auto"]} ${styles?.["w-95"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
        style={{ gap: "2rem",flexWrap:isMobile?"wrap":"nowrap" }}
      >
        <div className={`${styles?.[ isMobile?"w-90":"w-60"]} `}>
          <img
            //   height=
            style={{ height: "100%" }}
            className={`${styles?.["news"]} ${styles?.["w-100"]}`}
            src={config}
            alt=""
          />
        </div>
        <div
          style={{ lineHeight: "39px", textAlign: "justify" }}
          className={` ${styles?.["w-90"]}  ${styles?.["value-cover"]} `}
        >
          <div
            className={` ${styles?.["orange-background"]}  ${styles?.["head-lg"]}  ${styles?.["left"]} ${styles?.[isMobile?"head-lg":"head-xlg"]}  `}
          >
            ONE CONFIGURABLE PLATFORM.
          </div>
          <Spacer />
          <div
            className={`${styles?.["orange-background"]}  ${styles?.["head-lg"]}  ${styles?.["left"]} ${styles?.[isMobile?"head-lg":"head-xlg"]}  `}
          >
            MANY SOLUTIONS.
          </div>
          <Spacer height="2rem" />

          <div className={`   ${styles?.[isMobile?"head-xsm":""]}  `} style={{ lineHeight: "24px ", textAlign: "justify" }}>
            By combining hundreds of single-point solutions and thousands of
            data points into a single operating system, Intelligence Platform
            (iP) enables you to automate processes, drive performance strategy,
            and break down silos.
          </div>
          <Spacer height={"2rem"} />
          <div
            style={{ padding: isMobile?".2rem 2rem":".2rem 3rem" }}
            className={`   ${styles?.["white-back-button"]} ${styles?.["pointer"]} `}
            onClick={() => navigate("/contact")}
          >
            Learn More <span style={{ fontSize: "100px;" }}>&#8594;</span>
          </div>
        </div>
      </div>
      <div data-aos="fade-up">
        <SecuritySection />
      </div>
      <Media />
      <Spacer />
      <div data-aos="fade-right" className={`${styles?.["line-heading-cont"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          WE WORK WITH
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <WorkWithSection />
    </div>
  );
};
export default Home;

import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import { use } from "react";
import { useMediaQuery } from "react-responsive";

const FlexContainer = ({ data = {}, isLeft = false }) => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  return (
    <div
      data-aos={isLeft ? "fade-left" : "fade-right"}
      className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
      style={{
        gap: "2rem",
        flexWrap: isMobile ? "wrap" : "nowrap",
        lineHeight: isMobile ? "22px" : "32px",
        fontSize: isMobile ? ".8rem" : "1rem",
      }}
    >
      {isLeft && !isMobile ? (
        <>
          <div
            style={{
              textAlign: "justify",
            }}
            className={` ${styles?.[isMobile ? "w-100" : "w-50"]}  ${
              styles?.["value-cover"]
            } `}
          >
            <div className={`   ${styles?.["center"]} ${styles?.["w-fit"]}  `}>
              {data?.title2}
            </div>
            <h1
              className={`${styles?.["head-lg"]} ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `}
            >
              {data?.title}
            </h1>
            <div style={{ textAlign: "justify" }}>{data?.summary}</div>
            {/* <div onClick={()=>window.open(link,"_blank")} style={{ color:"#FF6B6B",fontWeight:"500" }}>Read More</div> */}
          </div>

          <div className={`${styles?.[isMobile ? "w-100" : "w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={data?.img} alt="" />
          </div>
        </>
      ) : (
        <>
          <div className={`${styles?.[isMobile ? "w-100" : "w-50"]} `}>
            <img className={`${styles?.["news-img"]}`} src={data?.img} alt="" />
          </div>
          <div
            style={{
              alignItems: "start",
              textAlign: "justify",
            }}
            className={`${styles?.["flex-column"]} ${
              styles?.[isMobile ? "w-100" : "w-50"]
            }  ${styles?.["value-cover"]} `}
          >
            <div className={`  ${styles?.["center"]} ${styles?.["w-fit"]}  `}>
              {data?.title2}
            </div>
            <h1
              className={`${styles?.["head-lg"]} ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `}
            >
              {data?.title}
            </h1>
            <div style={{ textAlign: "justify" }}>{data?.summary}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default FlexContainer;

import React from "react";
import styles from "../../styles/main.module.css";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";

const DefenceGrid = ({Categories=[]}) => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
 
  return (
    <div>
      <div
        style={{ alignItems: "start" }}
        className={`${styles?.["categories-container"]} ${styles?.["w-90"]} ${styles?.["m-auto"]}`}
      >
        {Categories?.map((data, i) => (
          <>
            <div
            data-aos="fade-left"
                data-aos-delay={`${i+1}00`}
              style={isMobile?{height:"100%"}:{
                height: "100%",
                borderRight:
                  i !== Categories.length - 1 ? "2px solid white" : "",
              }}
              //   className={`${styles?.["w-30"]}`}
            >
              <div
                style={{ gap: "10%", alignItems: "start" }}
                className={` ${styles?.["flex-column-str"]} ${styles?.[isMobile?"":"p-r-3"]}`}
              >
                <div>
                    <img style={{ width:"100%" }} src={data?.img} alt="" />
                </div>
              <p>
                {
                    data?.summary
                }</p>
              </div>
            </div>
            {/* <div style={{ height:"100%",width:"10px", background:"white" }}></div> */}
          </>
        ))}
      </div>
      <Spacer height={"2rem"}/>
      {/* <div
        style={{ borderRadius: ".5rem" }}
        className={` ${styles?.["orange-background"]} ${styles?.["w-35"]} ${styles?.["m-auto"]}  ${styles?.["center"]}  ${styles?.["head-md"]}  `}
      >
        Explore the Platform
      </div> */}
    </div>
  );
};
export default DefenceGrid;

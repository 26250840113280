import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import Spacer from "./Spacer";
import { useMediaQuery } from "react-responsive";

const FlexContainer2 = ({ data = {}, isLeft = false,cornerWidth="200px",cornerHeight="200px" }) => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  return (
    <>
    <div
      className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-center"]}  ${styles?.["value-cover"]}  `}
      style={isMobile?{flexWrap:"wrap",marginTop:"1rem"}:{ gap: "2rem",marginTop:"1rem" }}
    >
      {isLeft && !isMobile ? (
        <>
          <div
          data-aos="fade-right"
            style={{ lineHeight: "39px", textAlign: "justify" }}
            className={` ${styles?.["w-50"]}  ${styles?.["value-cover"]} `}
          >
            <div className={`${styles?.["head-lg"]}   ${styles?.["left"]} ${styles?.["w-fit"]}  `}>
              {data?.title2}
            </div>
            <Spacer height="1rem"/>
            <div
              className={`${styles?.["head-lg"]}  ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `}
            >
              {data?.title}
            </div>
            <Spacer height="3rem"/>
            <div style={{ lineHeight: "24px", textAlign: "justify" }}>
              {data?.summary}
            </div>
          </div>

          <div className={`${styles?.["w-50"]} ${styles?.["cover"]} `}>
          <div style={{ width:cornerWidth,height:cornerHeight }} className={`${styles?.["cornerDiv"]} `} ></div>

            <img className={`${styles?.["flex-img"]}`} src={data?.img} alt="" />
          </div>
        </>
      ) : (
        <>
          <div  className={`${styles?.[isMobile?"w-90":"w-50"]} ${styles?.["cover"]} `}>
            <div style={{ width:cornerWidth,height:cornerHeight }}  className={`${styles?.["cornerDivLeft"]} `} ></div>
            <img className={`${styles?.["flex-img"]}`} src={data?.img} alt="" />
          </div>
          <div
          data-aos="fade-left"
            style={{ lineHeight: "39px", textAlign: "justify" }}
            className={` ${styles?.[isMobile?"w-90":"w-50"]} ${styles?.[isMobile?"m-t-3":""]}  ${styles?.["value-cover"]} `}
          >
            <div className={`${styles?.["head-lg"]}   ${styles?.["left"]} ${styles?.["w-fit"]}  `}>
              {data?.title2}
            </div>
            <Spacer height="1rem"/>
            <div
              className={`${styles?.["head-lg"]}  ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `}
            >
              {data?.title}
            </div>
            <Spacer height={isMobile?"1rem":"3rem"}/>
            <div  style={{ lineHeight: "22px", textAlign: "left"}}>
              {data?.summary}
            </div>
          </div>
        </>
      )}
    </div>
    
    <Spacer height="2rem"/>
    </>
  );
};
export default FlexContainer2;

import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/whySpeed/MainCover";
import SoftwareSection from "../components/whySpeed/SoftwaresSection";
import WorkWithSection from "../components/home/WorkWithSection";
import Spacer from "../components/common/Spacer";
// import MainCover from "../components/home/MainCover";
// import LineHeading from "../components/home/lineHeading";
// import football from "../assets/football.png";
// import basketball from "../assets/basketball.png";
// import FlexContainer from "../components/common/FlexContainer";
// import BoldHRWhite from "../components/common/BoldHRWhite";
// import dataviz from "../assets/Home Page/data viz.jpg";
// import Spacer from "../components/common/Spacer";
// import PathPerformance from "../components/home/PathPerformance";
// import ground1 from "../assets/Home Page/ground1.jpg"
// import ground2 from "../assets/Home Page/ground2.jpg"
// import config from "../assets/Home Page/configurable.jpg"

// import Categories from "../components/home/Categories";
// import CoverContent from "../components/home/CoverContent";
// import SecuritySection from "../components/home/SecuritySection";
// import WorkWithSection from "../components/home/WorkWithSection";
const WhySpeed = () => {
  return (
    <div className={styles.wrap}>
      <MainCover />
      <SoftwareSection />
      <Spacer height="2rem"/>
      <div className={`${styles?.["line-heading-cont"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          WE WORK WITH
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <WorkWithSection isMore={true} />
    </div>
  );
};
export default WhySpeed;

import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import FlexContainer2 from "../components/common/FlexContainer2";
import more from "../assets/click on Intelligence platform/find formula for success.jpg";
import CustomCarousel from "../components/common/Slider";
import Tabs from "../components/IntellegencePlatform/Tabs";
import Spacer from "../components/common/Spacer";
import cover from "../assets/click on Intelligence platform/coaching & dev.jpg";
import mobileCover from "../assets/click on Intelligence platform/mobile-c&d.jpg";
import startup from "../assets/click on Intelligence platform/google.jpg";
import google2 from "../assets/click on Intelligence platform/google2.jpg";
import vision from "../assets/click on Intelligence platform/computer-vision.png";


import VideoSlider from "../components/common/VideoSlider";
import { useMediaQuery } from "react-responsive";


const PerformanceCandD= () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  const MainCoverData={
    title1:"ATHLETE MONITORING SOFTWARE",
    title2:"COACHING & ",
    title3:" DEVELOPMENT",
    summary:"Make your coaching and talent development approach a consistent, repeatable success by implementing everything from individual player advancement to comprehensive, organization-wide assessment.",
    cover:isMobile?mobileCover:cover
  }
  const tabData=[
    {
        tabName:"CORE CAPABILITIES",
        pages:[
            {
                page1:"",
                data:[
                    {
                        title:"Development Principles & Curriculum Management",
                        summary:"Create repeated success by systemizing paths and developing a curriculum for each developmental stage that is in line with your play ideas or the qualities that employers are looking for in a candidate."

                    },
                    {
                        title:"Individual Development Plans",
                        summary:"Establish personal objectives and plans of action to maximize social, tactical, technical, and mental/physical development. To monitor growth objectively, use data from training, games, classrooms, nutrition, and wellbeing."

                    },
                    {
                        title:"Assessments & Benchmarking",
                        summary:"To compare a player's performance and growth to that of other players and cohorts, gather and combine ratings, reviews, and testing data."

                    },
                    

                ]
            },{
              page2:"",
              data:[
                {
                  title:"Session Planning & Analysis",
                  summary:"Develop and disseminate training programs that are in line with performance and development goals; monitor and evaluate the quality of sessions, player engagement, and evaluations."

              },
             
              {
                  title:"Game Planning & Analysis",
                  summary:"Create and distribute game rosters; monitor and evaluate team configurations, player performance, minutes, and disciplinary actions; and track and analyze game outcomes."

              },
              {
                  title:"Communication & Player Engagement",
                  summary:"Use centralized calendar management and real-time messaging to communicate with players, coaches, and staff."

              },

              ]
            }
        ]
        

    },
    // {
    //     tabName:"FEATURE ADD ONS",
    //     pages:[
    //         {
    //             page1:"",
    //             data:[
    //                 {
    //                     title:"Injury lifecycle Management",
    //                     summary:"Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation."

    //                 },
    //                 {
    //                     title:"Treatment & rehab Management",
    //                     summary:"Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal."

    //                 },
    //                 {
    //                     title:"Exposure& Availability Management",
    //                     summary:"An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off."

    //                 },
    //                 {
    //                     title:"Medication Management & Governance",
    //                     summary:"Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation."

    //                 },
                   
    //                 {
    //                     title:"Reporting & Injury Surveillance",
    //                     summary:"Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location."

    //                 },
    //                 {
    //                     title:"Player Profile & Status",
    //                     summary:"Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk."

    //                 },


    //             ]
    //         }
    //     ]
        

    // },
    
]
const videos = [
  {
    img:startup,
    video:
      "https://x.com/i/status/1816851062147703188",
    summary:
      "All eyes on #ParisOlympics2024—but, back in India,@iitmadras has its sights set on #Olympics 2036. At #IITMSportsTechConclave, the institute said it will incubate 300 sports tech #startups to enable Indian athletes close the gap with global frontrunners.@akhil_edit reports",
    link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
    
  },
  {
    img:google2,
    video:
      "https://videos.pexels.com/video-files/6338366/6338366-uhd_2560_1440_25fps.mp4",
    summary:
      "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
    link:"https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette"

  },
  {
    img:vision,
    video:
      "https://videos.pexels.com/video-files/5972853/5972853-uhd_2560_1440_30fps.mp4",
    summary:
      "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
    link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

  },
];
  const WhatsDo = [
    {
      title: "FIND YOUR FORMULA FOR SUCCESS",
      title2: "",
      img: more,
      summary:
        "Teams and leagues deal with a lot of disjointed data regarding the potential, training, game performance, organizational depth, and coach and player development.Speeed.AI offers the insights and infrastructure needed to systemize your talent development pipeline, aggregate data sets, and improve coaching and talent initiatives for long-term, repeatable performance.",
    },
  ];
  return (
    <div
      style={{ gap: isMobile?"1.5rem":"3rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData} />
      <FlexContainer2
        cornerWidth={"70%"}
        cornerHeight={"80%"}
        data={WhatsDo[0]}
      />
      <Tabs tabData={tabData} />
      <Spacer height={isMobile?"2rem":"5rem"} />
      <div className={`${styles?.["line-heading-cont"]} ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SEEN & HEARD
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <VideoSlider slides={videos} />
      <Spacer height={"5rem"} />
    </div>
  );
};
export default PerformanceCandD;

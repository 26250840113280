import React from "react";
import styles from "../../styles/main.module.css";
import training from "../../assets/Click on why speed/Athlete training plan.jpg";
import onfield from "../../assets/Click on why speed/on feild.png"
import lineWhite from "../../assets/linewhite-background.png";
import Spacer from "../common/Spacer";
import FlexContainer2 from "../common/FlexContainer2";
import { useMediaQuery } from "react-responsive";
// import Typewriter from "../common/TypeWriter";
// import VoiceToText from "../common/Voice";
const SoftwareSection = () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})

  const sentences = [
    "  Helping you re-imagine patient experience​",
    " Streamlining your Hospital Workflows​",
    " Enhancing your Staff Productivity by Automating Routine Tasks​​",
    " Becoming force multipliers overcoming staff shortage​",
    " Empowering your Staff to Focus on Critical Patient Care​​",
    " Available 24X7, an infinitely scalable cost optimized workforce​",
    " Elevating healthcare delivery standards​​",
    " Improving patient experience​​",
  ];
  const WhatsDo = [
    {
      title: "MANY SOLUTIONS",
      title2:"ONE CONFIGORABLE , CLOUD-BASED PLATFORM",
      img: training,
      summary:
        "Our system aggregates thousands of data points into a single platform. accessible to the whole business. customized to meet your needs.",
    },
    // {
    //   title: "BEYOND",
    //   title2:"MORE THAN AN EMR",
    //   img: onfield,
    //   summary:
    //     "Data-driven solutions for coaching and development, league operations, performance improvement, and performance medicine are made possible by this dynamic, multidisciplinary platform.",
    // },
    {
        title: "ON-FEILD SUCCESS",
        title2:"DATA STRATEGY THAT LEADS TO",
        img: onfield,
        summary:
          "Prior to optimizing human performance, you must optimize your method. We support the implementation and operation of more efficient processes and systems to encourage faster success.",
      },
  ];
  return (
    <div>
      <div
        style={{ minHeight: "33rem", overflow: "hidden" }}
        className={`${styles?.["cover"]} `}
      >
        <div style={{ top:"-10%" }} className={`${styles?.["w-100"]} ${styles?.["whiteline"]} `}>
          <img className={` ${styles?.["w-100"]} `} src={lineWhite} alt="" />
        </div>
        <Spacer height={"5rem"} />
        <div data-aos="fade-up" style={{ gap: "1rem" }} className={`${styles?.["flex-column"]} `}>
          <div className={`${styles?.["head-xlg"]} ${styles?.["center"]} `}>
          SOFTWARE AS A <br />SUPERPOWER
          </div>

          <Spacer height={".5rem"} />
          <div style={{ fontWeight:"400" }} className={`${styles?.["head-sm"]} ${styles?.["center"]} ${styles?.[isMobile?"w-90":"w-50"]}`}>
          Speeed.AI's Intelligence Platform is a centralized, cutting-edge operating system that helps accomplish particular performance goals by automating and streamlining processes.
          </div>
        </div>
      </div>
      <div style={{ marginTop:"-200px" }}>
  
        <FlexContainer2 isLeft={true} data={WhatsDo[0]}/>
        <FlexContainer2 cornerHeight="100px" cornerWidth="100px" isLeft={false} data={WhatsDo[1]}/>


        

      </div>
      

    </div>
  );
};
export default SoftwareSection;

import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/Click on why speed/background.jpg";
import lineWhite from "../../assets/linewhite-background.png";

import logo1 from "../../assets/Brand-logos/iitb1 2.png";
import logo2 from "../../assets/Brand-logos/Stanford-seed-logo-2 1.png";
import logo3 from "../../assets/Brand-logos/Nvidia_logo.svg 1.png";
import logo4 from "../../assets/Brand-logos/sharda1.jpg 1.png";
import logo5 from "../../assets/Brand-logos/sai new1.jpg 1.png";
import logo6 from "../../assets/Brand-logos/Sharda-Hospital-Logo 1.png";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
// import Typewriter from "../common/TypeWriter";
// import VoiceToText from "../common/Voice";
const MainCover = () => {
  const isMobile=useMediaQuery({maxWidth:"400px"})
  const brands = [logo1, logo2, logo3, logo4, logo5, logo6];
  const sentences = [
    "  Helping you re-imagine patient experience​",
    " Streamlining your Hospital Workflows​",
    " Enhancing your Staff Productivity by Automating Routine Tasks​​",
    " Becoming force multipliers overcoming staff shortage​",
    " Empowering your Staff to Focus on Critical Patient Care​​",
    " Available 24X7, an infinitely scalable cost optimized workforce​",
    " Elevating healthcare delivery standards​​",
    " Improving patient experience​​",
  ];
  return (
    <div>
      <div
        style={{
          minHeight: "30.0625rem",
          backgroundImage: `url(${background})`,
          backgroundPosition: "top",
        }}
        className={`${styles?.["gradiant-blue"]} ${styles?.["cover"]} ${styles?.["backgroundImage"]}  `}
      >
        <Spacer height="7rem" />
        <div
          style={{ justifyContent: isMobile?"center":"end" }}
          className={`${styles?.["flex-gap"]} ${styles?.[""]} ${styles?.["="]}  `}
        >
          <div data-aos="fade-left" className={`${styles?.[isMobile?"w-80":"w-50"]}   `}>
            <h1 className={`${styles?.["head-lg"]}   `}>
              FROM THE{" "}
              <span className={`${styles?.["orange-background"]}   `}>
                FEILD
              </span>{" "}
              TO THE FUTURE
            </h1>
            <div
              style={{ fontWeight: "400" }}
              className={`${styles?.["head-sm"]} ${styles?.["inter"]}  `}
            >
              Created by practitioners, Speeed.AI turns data and <br />{" "}
              analytics into your greatest performance advantage.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainCover;

import React from "react";
import styles from "../styles/main.module.css";
import MainCover from "../components/IntellegencePlatform/MainCover";
import FlexContainer2 from "../components/common/FlexContainer2";
import more from "../assets/click on Intelligence platform/more time on feild.jpg";
import startup from "../assets/click on Intelligence platform/google.jpg";
import google2 from "../assets/click on Intelligence platform/google2.jpg";


import CustomCarousel from "../components/common/Slider";
import Tabs from "../components/IntellegencePlatform/Tabs";
import Spacer from "../components/common/Spacer";
import cover from "../assets/click on Intelligence platform/Performance Medicine.jpg";
import mobileCover from "../assets/click on Intelligence platform/mobile-medicine.jpg";
import vision from "../assets/click on Intelligence platform/computer-vision.png";


import VideoSlider from "../components/common/VideoSlider";
import { useMediaQuery } from "react-responsive";
import { data } from "react-router-dom";

const IntelligencePlatform = () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  const MainCoverData={
    title1:"MORE THAN EMR",
    title2:"PERFORMACE ",
    title3:"MEDICINE",
    summary:"Give your medical staff the single view they need with performance medicine software that goes far beyond basic compliance and the limitations of a traditional EMR.",
    cover:isMobile?mobileCover:cover
  }
  const videos = [
    {
      img:startup,
      video:
        "https://x.com/i/status/1816851062147703188",
      summary:
        "All eyes on #ParisOlympics2024—but, back in India,@iitmadras has its sights set on #Olympics 2036. At #IITMSportsTechConclave, the institute said it will incubate 300 sports tech #startups to enable Indian athletes close the gap with global frontrunners.@akhil_edit reports",
      link:"https://x.com/CNBCTV18News/status/1816851062147703188?t=xknZdPkiVdVvnlz9yoH5WA&s=08"
      
    },
    {
      img:google2,
      video:
        "https://videos.pexels.com/video-files/6338366/6338366-uhd_2560_1440_25fps.mp4",
      summary:
        "Digital Darwin Partners with Google to Enhance AI Innovation in Sports and Healthcare",
      link:"https://cxotoday.com/interviews/digital-darwin-revolutionizing-sports-tech-with-computer-vision-generative-ai-and-integrated-analytics/#google_vignette"

    },
    {
      img:vision,
      video:
        "https://videos.pexels.com/video-files/5972853/5972853-uhd_2560_1440_30fps.mp4",
      summary:
        "Digital Darwin: Revolutionizing Sports Tech with Computer Vision, Generative AI, and Integrated Analytics",
      link:"https://ians.in/vmpl/digital-darwin-partners-google-enhance-ai-innovation-sports-healthcare"

    },
  ];
  const WhatsDo = [
    {
      title: "MORE TIME ON THE FEILD",
      title2: "LESS RISK. FEWER GAPS",
      img: more,
      summary:
        "Traditional electronic medical records give teams and  practitioners fragmented views of player health and safety Performance Medicine gives a unified view of player health, injuries, habits, recovery and more, allowing medical staff to make more informed and impactful decisions.",
    },
  ];
  const tabData = [
    {
      tabName: "CORE CAPABILITIES",
      pages: [
        {
          page1: "",
          data: [
            {
              title: "Injury lifecycle Management",
              summary:
                "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
            },
            {
              title: "Treatment & rehab Management",
              summary:
                "Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal.",
            },
            {
              title: "Exposure& Availability Management",
              summary:
                "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
            },
            {
              title: "Medication Management & Governance",
              summary:
                "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
            },

            {
              title: "Reporting & Injury Surveillance",
              summary:
                "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
            },
            {
              title: "Player Profile & Status",
              summary:
                "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
            },
          ],
        },
      ],
    },
    // {
    //   tabName: "FEATURE ADD ONS",
    //   pages: [
    //     {
    //       page1: "",
    //       data: [
    //         {
    //           title: "Injury lifecycle Management",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },
    //         {
    //           title: "Treatment & rehab Management",
    //           summary:
    //             "Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal.",
    //         },
    //         {
    //           title: "Exposure& Availability Management",
    //           summary:
    //             "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
    //         },
    //         {
    //           title: "Medication Management & Governance",
    //           summary:
    //             "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
    //         },

    //         {
    //           title: "Reporting & Injury Surveillance",
    //           summary:
    //             "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
    //         },
    //         {
    //           title: "Player Profile & Status",
    //           summary:
    //             "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
  const MobileData=[
    {
      tabName: "CORE CAPABILITIES",
      pages: [
        {
          page1: "",
          data: [
            {
              title: "Injury lifecycle Management",
              summary:
                "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
            },
            {
              title: "Treatment & rehab Management",
              summary:
                "Create, edit, and share preventative maintenance and individual care plans quickly and simply in a single, adaptable portal.",
            },
            {
              title: "Exposure& Availability Management",
              summary:
                "An objective assessment of the actual effects of injuries on game and training availability can be obtained by using a flexible system to monitor, communicate, and analyze player availability due to illness, injury, and time off.",
            },
           
          ],
        },
        {
          page2:"",
          data:[
            {
              title: "Medication Management & Governance",
              summary:
                "Gather comprehensive data regarding injuries and oversee all associated records, tests, and recuperation.",
            },

            {
              title: "Reporting & Injury Surveillance",
              summary:
                "Get a comprehensive, secure, and compliant view of your medical history, condition, and treatment in one convenient location.",
            },
            {
              title: "Player Profile & Status",
              summary:
                "Gather personal information about each person, such as their physical and emotional health, recuperation, and training feedback, using a smartphone or an on-site kiosk.",
            },
          ]
        }
      ],
    },
  ];
  return (
    <div
      style={{ gap:isMobile?"1.5rem": "3rem", alignItems: "start" }}
      className={`${styles?.["flex-column"]}`}
    >
      <MainCover data={MainCoverData} />
      {isMobile?<Spacer height="2rem"/>:<></>}
      <FlexContainer2
        cornerWidth={"60%"}
        cornerHeight={"80%"}
        data={WhatsDo[0]}
      />
      <Tabs  tabData={isMobile?MobileData:tabData} />
      <Spacer height={isMobile?"2rem":"5rem"} />
      <div data-aos="fade-right" style={{padding:isMobile?".8rem":"0"}} className={`${styles?.["line-heading-cont"]}  ${styles?.["w-100"]}`}>
        <div
          style={{ width: "20%" }}
          className={`${styles?.["line-heading"]}`}
        ></div>
        <div
          className={`${styles?.["line-mid-heading"]} ${styles?.["head-lg"]}`}
        >
          SEEN & HEARD
        </div>
        <div
          style={{ border: "none", width: "70%" }}
          className={`${styles?.["line-heading"]}`}
        >
          {" "}
        </div>
      </div>
      <VideoSlider slides={videos} />
      <Spacer height={"5rem"} />
    </div>
  );
};
export default IntelligencePlatform;

import React from "react";
import styles from "../../styles/main.module.css";
import stats from "../../assets/linewhite-background.png";
import race from "../../assets/Group 1749.png";
import basketball from "../../assets/Home Page/basketball2.png";

import fencing from "../../assets/space.png";
import { useMediaQuery } from "react-responsive";

const PathPerformance = ({ isLeft = false }) => {
  const isMobile=useMediaQuery({maxWidth:"500px"})

  const WhatsDo = [
    {
      title: "BLUEPRINT",
      title2: "PLAN",
      img: race,
      summary:
        "It must be designed before it can be carried out. Together, we transform thousands of data points into a blueprint, an action plan that translates intelligence into success.",
    },
    {
      title: "UNLOCKED",
      title2: "PREPARE",

      img: basketball,
      summary:
        "Be prepared to face the situation. We track, optimize, and uncover new insights to unlock performance outcomes because we understand that preparation is essential to transformation.",
    },
    {
      title: "LIMITLESS",
      title2: "PERFORM",

      img: fencing,
      summary:
        "Together, transcend your limitations. Our clients are able to perform limitlessly because we inspire teams with a single, cohesive plan.",
    },
  ];
  return (
    <div className={` ${styles?.["cover"]}  `}>
      {WhatsDo?.map((data, i) => (
        <div
          
          className={`${styles?.["m-auto"]} ${styles?.["w-80"]} ${styles?.["flex-between"]}  ${styles?.["value-cover"]}  `}
          style={{ gap: "2rem",
            justifyContent:isMobile?i % 2?"end":"start":"space-between",
            flexWrap: isMobile ? "wrap" : "nowrap",
            lineHeight: isMobile ? "22px" : "32px",
            fontSize: isMobile ? ".8rem" : "1rem", }}
        >
          {i % 2 == 0 && !isMobile ? (
            <>
              <div
                data-aos="fade-left"
                style={{ lineHeight: "39px", textAlign: "justify" }}
                className={` ${styles?.["w-50"]}  ${styles?.["value-cover"]} `}
              >
                <h1
                  style={{ marginBottom: "-42px" }}
                  className={` ${styles?.["secondary-color"]}  ${styles?.["center"]} ${styles?.["head-xxlg"]}  `}
                >
                  {data?.title}
                </h1>
                <h1
                  className={` ${styles?.["head-xxlg"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `}
                >
                  {data?.title2}
                </h1>
                <div style={{ lineHeight: "32px", textAlign: "justify" }}>
                  {data?.summary}
                </div>
                {/* <div onClick={()=>window.open(link,"_blank")} style={{ color:"#FF6B6B",fontWeight:"500" }}>Read More</div> */}
              </div>

              <div className={`${styles?.["w-50"]} `}>
                <img
                  className={`${styles?.["performance-img"]}`}
                  src={data?.img}
                  alt=""
                />
              </div>
            </>
          ) : (
            <>
              <div className={`${styles?.[isMobile?"w-100":"w-50"]} `}>
                <img
                  className={`${styles?.["performance-img"]}`}
                  src={data?.img}
                  alt=""
                />
              </div>
              <div
                data-aos="fade-right"
                style={{
                  lineHeight: "39px",
                  // alignItems: "start",
                  textAlign: "justify",
                }}
                className={`${styles?.["flex-column"]} ${styles?.[isMobile?"w-100":"w-60"]}  ${styles?.["value-cover"]} `}
              >
                <h1
                  style={{ marginBottom: "-42px" }}
                  className={` ${styles?.["secondary-color"]}  ${styles?.["center"]} ${styles?.["head-xxlg"]}  `}
                >
                  {data?.title}
                </h1>
                <h1
                  className={` ${styles?.["head-xxlg"]}  ${styles?.["center"]} ${styles?.["w-fit"]}  `}
                >
                  {data?.title2}
                </h1>
                <div style={{ lineHeight:isMobile? "22px":"32px", textAlign: "justify" }}>
                  {data?.summary}
                </div>
              </div>
            </>
          )}
        </div>
      ))}

      <div className={`${styles?.["w-100"]} ${styles?.["whiteline-bottom"]} `}>
        <img className={` ${styles?.["w-100"]} `} src={stats} alt="" />
      </div>
    </div>
  );
};
export default PathPerformance;

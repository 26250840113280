import React from "react";
import styles from "../../styles/main.module.css";
import background from "../../assets/click on Intelligence platform/coaching & dev.jpg";
import Spacer from "../common/Spacer";
import { useMediaQuery } from "react-responsive";
// import Typewriter from "../common/TypeWriter";
// import VoiceToText from "../common/Voice";
const MainCover = ({ data = {} }) => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  console.log(data?.cover);
  return (
    <div
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-offset="0"
      style={{
        width: "100%",
        backgroundImage: `url(${data?.cover || "none"})`,
      }}
    >
      <div
        style={{
          backgroundPosition: "right",
        }}
        className={` ${styles?.["cover"]} ${styles?.["backgroundImage"]}  `}
      >
        <div>
          <img style={{ width: "100%" }} src={data?.cover} alt="" />
        </div>
        <div
          style={{
            justifyContent: isMobile ? "center" : "start",
            marginLeft: isMobile ? "0rem" : "5rem",
          }}
          className={`${styles?.["flex-gap"]} ${styles?.["m-l-3"]} ${styles?.["cover-content-title"]}  `}
        >
          <div
            data-aos-delay="2000"
            data-aos="fade-right"
            className={`${styles?.[isMobile ? "w-90" : "w-50"]} ${
              styles?.[isMobile ? "flex-column" : ""]
            }    `}
          >
            <div
              className={`${styles?.["head-md"]} ${
                isMobile ? styles?.["center"] : styles.left
              } `}
              style={{ color: "rgb(216 216 216)" }}
            >
              {data?.title1}
            </div>
            <div className={`${styles?.["head-lg"]}`}>
              {data?.title2}
              {isMobile ? data?.title3 : ""}
            </div>
            {!isMobile ? (
              <div
                style={{ marginTop: "-.9rem" }}
                className={`${styles?.["head-lg"]}`}
              >
                {data?.title3}
              </div>
            ) : (
              ""
            )}
            <Spacer height="1rem" />
            <div
              style={{ fontWeight: "400" }}
              className={`${styles?.["head-sm"]} ${styles?.["inter"]} ${
                styles?.[isMobile ? "center" : "left"]
              }  `}
            >
              {data?.summary}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainCover;

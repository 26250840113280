import React from "react";
import styles from "../../styles/main.module.css";
import Spacer from "../common/Spacer";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Categories = () => {
  const isMobile=useMediaQuery({maxWidth:"500px"})

  const navigate=useNavigate()
  const Categories = [
    {
      category: "SPORTS",
      title: "PLAY SMARTER",
      features: [
        "Unlock player and team performance, health and wellness with data-driven analysis and intelligence.",
        "Get future proofed – automate workflows, eliminate data silos, enable collaboration, streamline.",
        "Extend careers and systemize your player pipeline and recruitment process.",
      ],
    },
    {
      category: "DEFENSE",
      title: "OPTIMIZE YOUR HUMAN ASSETS",
      features: [
        "Establish and maintain a record of service member performance from pipeline to retirement",
        "Scale and systemize service member development to elevate performance and drive specific performance outcomes.",
        "Improve pipeline efficiency and effectiveness by tracking and optimizing student or service member progress.",
      ],
    },
    {
      category: "ANALYTICS",
      title: "DATA THAT DRIVES RESULTS",
      features: [
        "Assess data strategy and process, identify improvement areas and implement systems to reach desired outcomes.",
        "Evaluate the type, volume and severity of injuries, where and how they occur, and their impact on success.",
        "Gain data-based insights into the success strategies of high-performing teams and the changes you can employ to win.",
      ],
    },
  ];
  return (
    <div>
      <div
        style={{ alignItems: "start" }}
        className={`${styles?.["categories-container"]} ${styles?.["w-95"]} ${styles?.["m-auto"]}`}
      >
        {Categories?.map((data, i) => (
          <>
            <div
              style={{
                height: "100%",
                borderRight:
                  i !== Categories.length - 1 && !isMobile ? "2px solid white" : "",
              }}
              //   className={`${styles?.["w-30"]}`}
            >
              <div
                style={{ gap: "10%", alignItems: "start" }}
                className={` ${styles?.["flex-column-str"]} ${styles?.["p-r-3"]}`}
              >
                <div
                  className={`${styles?.["head-lg"]} ${styles?.["p-l-3"]} ${styles?.["center"]} ${styles?.["w-fit"]}  `}
                >
                  {data?.category}
                </div>
                <div
                  className={` ${styles?.["orange-background"]}  ${styles?.["center"]} ${styles?.["head-md"]}  `}
                >
                  {data?.title}
                </div>
                <Spacer height="2rem"/>
                <ul style={{ lineHeight:isMobile?"1.3": "2" }}>
                  {data?.features?.map((feature) => (
                    <li className={`${styles?.["p-b-3"]} ${styles?.[isMobile?"head-xsm":"p-b-3"]}`}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div style={{ height:"100%",width:"10px", background:"white" }}></div> */}
          </>
        ))}
      </div>
      <Spacer height={"2rem"}/>
      <div
        style={{ borderRadius: ".5rem" }}
        onClick={()=>navigate("explore")}
        className={` ${styles?.["orange-background"]} ${styles?.["w-35"]} ${styles?.["m-auto"]}  ${styles?.["center"]} ${styles?.["pointer"]} ${styles?.["head-md"]}  `}
      >
        Explore the Platform
      </div>
    </div>
  );
};
export default Categories;

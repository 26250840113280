import React from "react";
import styles from "../../styles/main.module.css";
import { useMediaQuery } from "react-responsive";

const CoverContent = ({ img = "", text = "", text2 = "" }) => {
    const isMobile=useMediaQuery({maxWidth:"500px"})
  return (
    <div className={`${styles?.["cover"]} ${styles?.["w-100"]}`}>
      <img className={` ${styles?.["w-100"]}`} src={img} alt="" />
      <div
      style={isMobile?{marginTop:"1rem"}:{}}
        className={`${styles?.["cover-content-title"]}  ${styles?.["w-100"]}`}
      >
        <h1 className={`${styles?.["center"]}  ${styles?.[isMobile?"head-lg":"head-xxlg"]}`}>
          {text} <br />
          {text2}
        </h1>
      </div>
    </div>
  );
};
export default CoverContent;

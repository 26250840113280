import React, { useState } from "react";
import styles from "../../styles/main.module.css";
import CustomCarousel from "../common/Slider";
import ground1 from "../../assets/click on Intelligence platform/blur-ground.png";
import { useMediaQuery } from "react-responsive";

const Tabs = ({tabData,isSports=false}) => {
  const isMobile=useMediaQuery({maxWidth:"500px"})
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div style={{ width:"90%",margin:"auto"}}  data-aos="fade-up" className={`${styles?.["cover"]}`}>
      <div className={`${styles?.["flex-center"]} ${styles?.["cover"]}`}>
        {
            tabData?.map((data,i)=>(
                <div
                key={i}
                onClick={() => setActiveTab(i)}
                className={`${
                  activeTab == i
                    ? styles?.["fill-background"]
                    : styles?.["blank-background"]
                } ${styles?.["pointer"]}`}
              >
                <h1>{data?.tabName}</h1>
              </div>
 
            ))
        }
       
        {/* <div
          onClick={() => setActiveTab(1)}
          className={`${
            activeTab == 1
              ? styles?.["orange-background"]
              : styles?.["blank-background"]
          } ${styles?.["pointer"]}`}
        >
          <h1>FEATURE ADD ONS</h1>
        </div> */}
      </div>
      <div
        className={`${styles?.["tab-container"]}  ${styles?.["m-auto"]}`}
      >
        <CustomCarousel slides={tabData[activeTab]?.pages} />
      </div>
      {
        !isSports? <div
        style={{ bottom: isMobile?"-72%":"-45%",zIndex:"-100" }}
        className={`${styles?.["cover-content-title"]}`}
      >
        <img className={`${styles?.["w-100"]}`} src={ground1} alt="" />
      </div>:<></>
      }
    </div>
  );
};
export default Tabs;
